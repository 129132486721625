


















import { Vue, Component } from 'vue-property-decorator';
import { getDictConfig } from '@/api/other';
@Component({
  name: 'BaseIssue'
})
export default class extends Vue {
  list: any[] = [];

  async getList() {
    const { payload } = await getDictConfig('QUESTION');
    this.list = payload;
  }

  created() {
    this.getList();
  }
}
